.btn {
	border-radius: 2px;
	font-size: 16px;
	font-weight: 700;
	font-family: $font-family-secondary;
	text-transform: uppercase;

	/* btn-primary */
	&.btn-primary {
		background: $primary;
		border-color: $primary;
		color: $white;
		&:hover {
			border-color: $secondary;
			background: $secondary;
			color: $white;
		}
	}

	/* btn-outline-primary */
	&.btn-outline-primary {
		&:hover {
			color: $white;
		}
	}

	/* btn-secondary */
	&.btn-secondary {
		border-color: $secondary;
		background: $secondary;
		color: $white;
		&:hover {
			background: $primary;
			border-color: $primary;
			color: $white;
		}
	}

	/* btn-outline-secondary */
	&.btn-outline-secondary {
		&:hover {
		}
	}
}

// btn-back
// =========================================================================
.btn-back {
	display: inline-block;
	font-size: 14px;
	font-weight: 500;
	text-decoration: none;
	text-transform: uppercase;
	&::before {
		display: inline-block;
		content: "\f177";
	}
	&:hover {
		color: $white;
	}
}

// btn-down
// =========================================================================
.btn-down {
	@extend .btn;
	@extend .btn-secondary;
	&:hover {
		border-color: $white;
		background: $white;
	}
}
