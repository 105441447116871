// headings
h1 {
	@include media-breakpoint-down(lg) {
		font-size: $h1-font-size-mobile;
	}
}
&.home h2 {
	font-size: $h1-font-size;
	@include media-breakpoint-down(lg) {
		font-size: $h1-font-size-mobile;
	}
}

// img
img {
	max-width: 100%;
	height: auto;
}

// transition
a,
.btn,
.owl-btn,
.card-btn {
	transition: 0.5s !important;
}

// form
.form {
	padding: 15px;
	@include media-breakpoint-up(xl) {
		padding: 30px;
	}
	border: 1px solid #ced4da;
	border-radius: 0.25rem;
}

// handwritten
p.handwritten {
	font-size: 48px;
	line-height: 1;
	font-family: $font-family-tertiary;
	@include media-breakpoint-down(lg) {
		font-size: 36px;
	}
}

// link
a.link-arrow {
	display: inline-flex;
	align-items: center;
	line-height: 1;
	font-weight: 700;
	text-transform: uppercase;
	text-decoration: none;
	&::after {
		content: "\f0a9";
		margin-left: 10px;
		color: $secondary;
		font-size: 18px;
		font-weight: 400;
		font-family: $font-awesome;
	}
	&:hover {
		margin-right: -5px;
	}
	&:focus {
		box-shadow: none;
	}
}

// home
&.home {
	.lead-section {
		margin-bottom: 0 !important;
	}
}
&:not(.home) {
	.lead-section {
	.container-holder{
		
		.one{
			padding: 3vh 0;
			
		}
		.two:has(.mini-sab){
			order: -1;
		}
	}
	}
}
