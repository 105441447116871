// bg-dark
// =========================================================================
section {
	&.bg-dark {
		margin: 0;
		padding: 6vh 0;
		a {
			color: $body-color--dark;
			&:hover {
				color: $secondary;
			}
		}
	}
	&.bg-light {
		position: relative;
		margin-top: 0;
		margin-bottom: calc(6vh + 71px);
		padding: 6vh 0;
		background: $light;
		&::before {
			content: "";
			z-index: 100;
			position: absolute;
			left: 0;
			right: 0;
			bottom: calc(100% - 12px);
			width: 100%;
			height: 37px;
			//margin-top: -1px; /* pixel fix */
			background: url("/images/visual-brush-green-light.svg") repeat-x;
		}
		&::after {
			content: "";
			z-index: 100;
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			width: 100%;
			height: 71px;
			margin-top: -1px; /* pixel fix */
			background: url("/images/visual-wave-green-light.svg") repeat-x;
		}
	}
}

// lead-section
// =========================================================================
.lead-section {
	position: relative;
	@extend .bg-dark;
	margin-bottom: calc(6vh + 71px) !important;
	&::after {
		content: "";
		z-index: 100;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		width: 100%;
		height: 71px;
		margin-top: -1px; /* pixel fix */
		background: url("/images/visual-wave-green-neon.svg") repeat-x;
	}

	.container-two-columns {
		.container-holder {
			> .column.one {
				@include media-breakpoint-up(xl) {
					//	@include make-col(7);
					flex-basis: 100%;
					max-width: 100%;
					padding-left: 15px!important;
				}
			}
			> .column.two {
				@include media-breakpoint-up(xl) {
					// @include make-col(5);
					flex-basis: 100%;
					max-width: 100%;
					padding-left: 15px !important;
					z-index: 200;
				}
			}
		}
	}

	// bundles
	.info-nav {
		@extend .d-flex;
		@extend .flex-wrap;
		@extend .align-items-center;
		@extend .justify-content-between;
		margin-bottom: 30px;
	}

	// properties (accommodation)
	.properties {
		.icon {
			display: inline-block;
			margin-right: 10px;
		}
	}

	// handwritten
	.handwritten {
		font-size: 40px;
	}

	// mini-sab
	.mini-sab {
		padding: 15px 25px;
		background: #cae08f;
		border-radius: 10px;
		width: 100%;
		
		@include media-breakpoint-up(lg) {
			max-width: max-content;
		}
		.heading {
			// color: $black;
			// font-weight: 700;
			// font-family: $font-family-secondary;
			display: none;
		}
	}
}

// content-section
// =========================================================================
&.accommodation-detail {
	.content-section {
		// smooth zoek en boek
		.column.one,
		.column.three {
			flex-basis: 66.66% !important;
			max-width: 66.66% !important;
			@include media-breakpoint-down(lg) {
				flex-basis: 100% !important;
				max-width: 100% !important;
			}
		}
	}
}
.content-section {
	position: relative;
	margin: 6vh 0;
	// intro/outro
	.intro {
		.container-holder {
			max-width: 750px;
			margin-bottom: 30px;
			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	.outro {
		.container-holder {
			margin-top: 30px;
			p:last-of-type {
				margin-bottom: 0;
			}
		}
	}

	// facility-blocks
	.facility-blocks {
		.grid-items {
			.item {
				@include media-breakpoint-down(sm) {
					@include make-col(6);
				}
				@include media-breakpoint-up(lg) {
					@include make-col(3);
				}
			}
		}
		.card {
			&::after {
				content: "\f0a9";
				position: absolute;
				right: 15px;
				bottom: 15px;
				color: $white;
				font-size: 24px;
				@include media-breakpoint-down(lg) {
					font-size: 20px;
				}
				font-weight: 400;
				line-height: 1;
				font-family: $font-awesome;
			}
			.card-body {
				.card-caption {
					.card-title {
						@include media-breakpoint-down(lg) {
							font-size: 18px;
						}
					}
					.card-subtitle,
					.card-description {
						display: none;
					}
				}
			}
			&:hover {
				background: $primary;
			}
		}
	}
}

// banner-section
// =========================================================================
.banner-section {
	position: relative;
	&::before {
		content: "";
		z-index: 90;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 71px;
		margin-top: -1px; /* pixel fix */
		background: url("/images/visual-wave-white.svg") repeat-x;
	}
	.owl-carousel {
		height: 70vh;
		min-height: 300px;
		max-height: 700px;
		.item {
			height: 70vh !important; /* overide */
			min-height: 300px;
			max-height: 700px;
		}
		.owl-dots {
			bottom: 60px;
		}
		.owl-nav {
			display: none;
		}
	}
}

// bundle-overview-section
// =========================================================================
.bundle-overview-section {
	margin: 6vh 0;

	// collection
	.collection {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(xxl) {
						@include make-col(4);
					}
				}
			}
		}
	}
}

// bundle-detail-section
// =========================================================================
.bundle-detail-section {
	margin: 6vh 0;
}

// booking-section
// =========================================================================
.booking-section {
	@extend .bg-light;
	margin-top: calc(6vh + 25px) !important;
	h2 {
		margin-bottom: 30px;
		text-align: center;
	}
}

// news-section
// =========================================================================
.news-section {
	.container {
		max-width: 750px;
	}
	// collection
	.collection {
		&.grid {
			.grid-items {
				margin-bottom: -6vh;
				.item {
					@include media-breakpoint-up(sm) {
						@include make-col(12);
					}
					@include media-breakpoint-up(md) {
						@include make-col(12);
					}
					@include media-breakpoint-up(lg) {
						@include make-col(12);
					}
					@include media-breakpoint-up(xxl) {
						@include make-col(12);
					}
					margin-bottom: 6vh;
					.card {
						background: none;
						border-radius: 0;
						color: $black;
						.card-image {
							display: none;
						}
						.card-body {
							padding: 0;
							.card-caption {
								.card-title-link {
									.card-title {
										color: $black;
										@include media-breakpoint-up(xl) {
											font-size: 30px;
										}
									}
									&:hover {
										.card-title {
											color: $secondary;
										}
									}
								}
								.card-subtitle,
								.card-description {
									display: block;
									color: $black;
								}
							}
							.card-buttons {
								.card-btn {
									@extend a.link-arrow;
									color: $black;
									border: none;
									padding: 0;
									background: none;
									&:hover {
										color: $secondary;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
