.collection {
	// grid
	&.grid {
		.grid-items {
			.item {
				@include media-breakpoint-up(xxl) {
					@include make-col(3);
				}
			}
		}
		.card {
			background: $primary-dark;
			border: none;
			border-radius: 10px;
			color: $white;
			text-decoration: none;
			.card-image {
				overflow: hidden;
				.card-image-link {
					@extend .d-block;
					.card-img {
						transition: 0.5s;
					}
					&:hover {
						.card-img {
							transform: scale(1.03);
						}
					}
					.card-image-caption {
						z-index: 10;
					}
				}
			}
			.card-body {
				background: none;
				@include media-breakpoint-up(xl) {
					padding: 25px;
				}
				.card-caption {
					.card-title,
					.card-subtitle,
					.card-description,
					.card-prices {
						color: $white;
					}
					.card-prices {
						margin-top: 1rem;
						.card-price {
							&.discount {
								font-size: 14px;
								text-decoration: line-through;
							}
						}
					}
				}
				.card-buttons {
					.card-btn {
						@extend .btn-primary;
					}
				}
			}
		}
	}

	// tiles
	&.tiles {
		&.grid {
			.grid-items {
				.item {
					@include media-breakpoint-up(lg) {
						@include make-col(6);
					}
					@include media-breakpoint-up(xl) {
						@include make-col(6);
					}
					@include media-breakpoint-up(xxl) {
						@include make-col(6);
					}
				}
			}
			.card {
				border-radius: 0;
				border: none;
				&::after {
					content: "\f0a9";
					position: absolute;
					right: 15px;
					bottom: 15px;
					color: $white;
					font-size: 36px;
					@include media-breakpoint-down(lg) {
						font-size: 24px;
					}
					font-weight: 400;
					line-height: 1;
					font-family: $font-awesome;
					transition: 0.5s;
				}
				.card-image {
					overflow: hidden;
					.card-img {
						transition: 0.5s;
					}
				}
				.card-img-overlay {
					display: flex;
					justify-content: flex-end;
					background: none;
					border-radius: 0;
					@include media-breakpoint-up(xl) {
						padding: 25px;
					}
					.card-caption {
						flex: none;
						.card-title {
							margin-bottom: 0;
							font-size: 30px;
							@include media-breakpoint-down(lg) {
								font-size: 24px;
							}
							text-shadow: none;
						}
						.card-subtitle,
						.card-description {
							display: none !important;
						}
					}
				}
				&:hover {
					&::after {
						color: $secondary;
					}
					.card-image {
						.card-img {
							transform: scale(1.03);
						}
					}
				}
			}
		}
	}
}
