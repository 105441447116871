// colors
$green: #a5bc00;
$green-light: #ecefd7;
$green-dark: #5d760a;
$green-neon: #95c11f;
$orange: #e94e1b;
$white: #fff;
$black: #000;

// theme-colors
$primary: $green;
$primary-light: $green-light;
$primary-dark: $green-dark;
$primary-neon: $green-neon;
$secondary: $orange;

$light: $green-light;
$dark: $green-neon;

// body
$body-bg: $white;
$body-color: $black;

// links
$link-color: $black;
$link-hover-color: $secondary;

// typography
@import url('https://fonts.googleapis.com/css2?family=Ingrid+Darling&family=Mulish:wght@400;500;700&family=Outfit:wght@400;500;700&display=swap');
$font-family-primary: 'Mulish', sans-serif;
$font-family-secondary: 'Outfit', sans-serif;
$font-family-tertiary: 'Ingrid Darling', cursive;

$font-awesome: 'Font Awesome 5 Pro';
$font-family-base: $font-family-primary;

$font-size-base: 1rem; // 16px
//$line-height-base: 1.3;

$h1-font-size: $font-size-base * 3.125; // 50px
$h2-font-size: $font-size-base * 1.875; // 30px
$h3-font-size: $font-size-base * 1.5; // 24px
$h4-font-size: $font-size-base * 1.25; // 20px
$h5-font-size: $font-size-base * 1.125; // 18px
$h6-font-size: $font-size-base * 1; // 16px

$h1-font-size-mobile: $font-size-base * 2.25; // 36px

$headings-color: $primary;
$headings-font-family: $font-family-secondary;
$headings-font-weight: 700;

// build options
$use-sticky-header: false;
$use-html-fontsize-reset: true;
