// header
// =========================================================================
.header {
	z-index: 998;
	position: sticky;
	top: 0;
	left: 0;
	right: 0;
	padding: 10px 0 20px 0;
	background: $white;

	&::after {
		content: "";
		position: absolute;
		top: calc(100% - 13px);
		left: 0;
		right: 0;
		width: 100%;
		height: 19px;
		//margin-top: -1px; /* pixel fix */
		background: url("/images/visual-brush-green-neon.svg") repeat-x;
	}

	.container-fluid {
		@include media-breakpoint-up(xl) {
			padding: 0 45px;
		}
		.container-holder {
			.column {
				flex-flow: row wrap;
				align-items: center;
			}
		}
	}

	// menu-toggle
	.menu-toggle {
		@include media-breakpoint-up(xl) {
			display: none;
		}
		.navbar-toggler {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 36px;
			height: 36px;
			margin: 0 15px 0 0;
			background-color: $primary-light;
			border-radius: 2px;
			border: none;
			color: $black;
			font-size: 20px;
			transition: 0.5s;
			text-align: center;
			&[aria-expanded="true"] {
				i {
					&::before {
						content: "\f00d";
					}
				}
			}
		}
	}

	// logo
	.logo {
		width: 150px;
		@include media-breakpoint-up(xl) {
			width: 280px;
		}
		a {
			@extend .d-block;
		}
	}

	// menu
	.menu {
		@include media-breakpoint-down(lg) {
			order: 4;
			.navbar-nav {
				.nav-item {
					font-weight: 500;
					.nav-link {
						padding: 0.5rem 0;
						color: $black;
						&:hover {
							color: $secondary;
						}
					}
					&.active {
						> .nav-link {
							color: $secondary;
						}
					}
					.dropdown-menu {
						position: static !important;
						transform: none !important;
						margin: 0 0 0 15px;
						padding: 0;
						border: none;
						background-color: transparent;
					}
				}
			}
		}
		@include media-breakpoint-up(xl) {
			display: flex;
			flex-basis: auto;
			.navbar-nav {
				flex-direction: row;
				justify-content: center;
				margin-left: auto;
				.nav-item {
					margin: 0 15px;
					font-size: 16px;
					font-weight: 500;
					.nav-link {
						color: $black;
						&:hover {
							color: $secondary;
						}
					}
					&.active {
						.nav-link {
							color: $secondary;
						}
					}
				}
				.dropdown-menu {
					background: $primary-light;
					border-radius: 0;
					box-shadow: 3px 3px 12px rgba($black, 0.15);
					.nav-item {
						margin: 0;
						.nav-link {
							color: $black;
							padding: 0.5rem 1rem;
							&:hover {
								color: $secondary;
								text-decoration: none;
							}
						}
						&.active {
							> .nav-link {
								color: $secondary;
							}
						}
					}
				}
			}
		}
	}

	// book-button
	.book-button {
		margin-left: auto;
		@include media-breakpoint-down(xs) {
			margin-top: 25px;
			font-size: 15px;
		}
		ul {
			margin: 0 0 0 15px;
			li {
				margin-right: 0;
				font-weight: 500;
				a {
					display: block;
					color: $secondary;
					text-decoration: none;
					&:hover {
						color: $black;
					}
				}
			}
		}
	}

	// site-switcher
	.site-switcher {
		@include media-breakpoint-down(xs) {
			position: absolute;
			top: 0;
			right: 15px;
		}
		margin-left: 30px;
		.language {
			flex-direction: row;
			margin-bottom: 0;
			.nav-item {
				+ li {
					margin-left: 5px;
					@include media-breakpoint-up(xl) {
						margin-left: 10px;
					}
				}
				.nav-link {
					display: block;
					padding: 0;
					border-radius: 100%;
					img {
						display: block;
						width: 21px;
						height: 21px;
					}
				}
				&:hover:not(.active) {
					.nav-link {
						box-shadow: 0 0 6px rgba($black, 0.3);
					}
				}
			}
		}
	}
}
