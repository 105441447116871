// footer
.footer {
	margin-top: 10vh;

	// footer-partners
	.footer-partners {
		margin-bottom: 6vh;
		ul {
			@extend .list-unstyled;
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: space-around;
			margin-bottom: 0;
			li {
				margin: 15px;
			}
		}
	}

	// footer-outro
	.footer-outro {
		position: relative;
		@extend .bg-dark;
		padding-bottom: 0 !important;
		&::before {
			content: "";
			z-index: -1;
			position: absolute;
			bottom: calc(100% - 6px);
			left: 0;
			right: 0;
			width: 100%;
			height: 19px;
			background: url("/images/visual-brush-green-neon.svg") repeat-x;
		}
		&::after {
			content: "";
			z-index: 100;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			height: 71px;
			margin-bottom: -1px; /* pixel fix */
			background: url("/images/visual-wave-white-up.svg") repeat-x bottom;
		}

		// footer-text
		.footer-text {
			h2 {
				font-size: 36px;
			}
			ul {
				@extend .list-unstyled;
				margin: 30px 0;
				li {
					font-weight: 700;
					a {
						text-decoration: none;
					}
					a[href^="tel:"] {
						&::before {
							content: "\f095";
							display: inline-block;
							width: 24px;
							font-family: $font-awesome;
							font-weight: 400;
						}
					}
					a[href^="mailto:"] {
						&::before {
							content: "\f0e0";
							display: inline-block;
							width: 24px;
							font-family: $font-awesome;
							font-weight: 400;
						}
					}
				}
			}
		}

		// visual-birds
		.visual-birds {
			margin: 0 auto 15px auto;
			padding-left: 25%;
		}

		// visual-deer
		.visual-deer {
			margin: 0 auto;
			@include media-breakpoint-up(xl) {
				margin-bottom: 45px;
			}
		}
	}

	// footer-socket
	.footer-socket {
		padding: 20px 0;
		.column.one {
			@include media-breakpoint-down(md) {
				order: 1;
			}
			@include media-breakpoint-up(md) {
				@include make-col(12);
			}
			@include media-breakpoint-up(lg) {
				@include make-col(8);
			}
			@include media-breakpoint-up(xl) {
				@include make-col(7);
			}
		}
		.column.two {
			@include media-breakpoint-down(md) {
				justify-content: center;
			}
			@include media-breakpoint-up(md) {
				@include make-col(12);
			}
			@include media-breakpoint-up(lg) {
				@include make-col(4);
			}
			@include media-breakpoint-up(xl) {
				@include make-col(5);
				justify-content: flex-end;
			}
			flex-flow: row wrap;
		}

		// logo
		.logo {
			max-width: 220px;
			margin-bottom: 20px;
			@include media-breakpoint-down(md) {
				margin-left: auto;
				margin-right: auto;
			}
			a {
				@extend .d-block;
			}
		}

		// footer-link
		.footer-link {
			.list {
				@include media-breakpoint-down(md) {
					justify-content: center;
					text-align: center;
				}
				.list-item {
					font-size: 14px;
					.link {
						text-decoration: none;
						&:hover {
							text-decoration: underline;
						}
					}
				}
			}
		}

		// footer-text
		.footer-text {
			.handwritten {
				margin-bottom: 0;
				font-size: 40px;
			}
		}

		// footer-logolink
		.footer-logolink {
			margin-left: 15px;
			.list {
				.list-item {
					margin-left: 5px;
					font-size: 24px;
					.link {
						color: $secondary;
						&:hover {
							color: $black;
						}
					}
				}
			}
		}
	}
}
